import React from "react"
import { DatoContentBaseProps } from "../../types"
import Hero from "@components/Hero"

interface DatoCmsComponentHeroProps extends DatoContentBaseProps {}

const DatoCmsComponentHero: React.FC<DatoCmsComponentHeroProps> = ({
  header,
  headerFontSize,
  headerMobileFontSize,
  subheader,
  subheaderFontSize,
  subheaderMobileFontSize,
  subheaderFontStyle,
  swapHeaderPositions,
  useTextBackgroundOverlay,
  backgroundImage,
}) => {
  return (
    <Hero
      header={header}
      headerFontSize={headerFontSize}
      headerMobileFontSize={headerMobileFontSize}
      subheader={subheader}
      subheaderFontSize={subheaderFontSize}
      subheaderMobileFontSize={subheaderMobileFontSize}
      subheaderFontStyle={subheaderFontStyle}
      swapHeaderPositions={swapHeaderPositions}
      useTextBackgroundOverlay={useTextBackgroundOverlay}
      backgroundImage={backgroundImage.responsiveImage}
    />
  )
}

export default DatoCmsComponentHero
