import React from "react"
import styled, { css, CSSObject } from "styled-components"
import { up, down } from "styled-breakpoints"
import colors from "@constants/colors"
import { DatoResponsiveImage } from "@dato/types"

export const Container = styled.div<{
  image: string
  mobileImage?: string
  bgColor: string | null
}>`
  ${({ image, mobileImage, bgColor }) => css`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-image: ${mobileImage ? `url(${mobileImage})` : `url(${image})`};
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: ${bgColor || colors.slateLight};

    ${down("laptop", "landscape")} {
      background-image: url(${image});
    }

    ${up("laptop")} {
      background-image: url(${image});
    }
  `}
`

export interface BackgroundImageProps {
  image: DatoResponsiveImage
  mobileImage?: DatoResponsiveImage
  style?: CSSObject
  children?: React.ReactNode
}

const BackgroundImage: React.FC<BackgroundImageProps> = ({
  image,
  mobileImage,
  style,
  children,
}) => {
  return (
    <Container
      image={image.src}
      mobileImage={mobileImage?.src}
      style={style}
      bgColor={image.bgColor}
    >
      {children}
    </Container>
  )
}

export default BackgroundImage
