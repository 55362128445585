import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import colors from "@constants/colors"
import { Arrow } from "@components/icons"
import { gsap } from "gsap"
import _ from "lodash"

export const Container = styled.div`
  z-index: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  display: flex;
`

const Track = styled.div`
  opacity: 0.1;
  width: 100%;
  height: 1px;
  background: ${colors.black};
`

export const Bar = styled.div`
  position: absolute;
  will-change: transform;
  height: 1px;
  background: ${colors.black};
`

export const Buttons = styled.div`
  display: none;

  ${up("laptop")} {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    margin-left: 32px;
  }
`

const Button = styled.button`
  margin: 0;
  padding: 0 16px;
  border: none;
  background: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

export const LeftArrow = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 24px;
  height: 24px;
`

export const RightArrow = styled(LeftArrow)`
  transform: rotate(180deg);
`

interface ScrollbarProps {
  containerRef: React.RefObject<HTMLDivElement>
  arrowColor?: any
}

const Scrollbar: React.FC<ScrollbarProps> = ({ containerRef, arrowColor }) => {
  const barRef = React.createRef<HTMLDivElement>()
  const trackRef = React.createRef<HTMLDivElement>()

  const calculateScroll = () => {
    const container = containerRef.current
    const track = trackRef.current
    const bar = barRef.current

    if (container && track && bar) {
      // Don't show the scrollbar if there is no overflows
      const hasOverflow = container.scrollWidth > container.clientWidth
      if (!hasOverflow) {
        return
      }

      // Calcuate the current scroll percentage (0 to 1)
      const scrollPercentage =
        1 -
        (container.scrollWidth - container.scrollLeft - container.clientWidth) /
          (container.scrollWidth - container.clientWidth)

      // Calculate the bar width
      const trackWidth = track.clientWidth
      const barWidth =
        trackWidth * (container.clientWidth / container.scrollWidth)

      // Calculate the translation of the bar
      const widthDiff = trackWidth - barWidth
      const translateX = widthDiff * scrollPercentage

      // Set the styling of the bar
      bar.style.width = `calc(${barWidth}px)`
      bar.style.transform = `translateX(${translateX}px)`
    }
  }

  const onPrev = () => {
    const container = containerRef.current
    const cardSize = container?.children[0]?.clientWidth

    if (window && container && cardSize) {
      const paddingOffset = parseFloat(
        window
          .getComputedStyle(container, null)
          .getPropertyValue("padding-left")
      )
      const offset =
        container.scrollLeft + container.clientWidth === container.scrollWidth
          ? paddingOffset
          : 0
      const left = _.clamp(
        container.scrollLeft - (cardSize * 3 + offset),
        0,
        container.scrollWidth - container.clientWidth
      )
      gsap.to(container, { duration: 0.4, scrollLeft: left, ease: "power4" })
    }
  }

  const onNext = () => {
    const container = containerRef.current
    const cardSize = container?.children[0]?.clientWidth

    if (window && container && cardSize) {
      const paddingOffset = parseFloat(
        window
          .getComputedStyle(container, null)
          .getPropertyValue("padding-left")
      )
      const offset =
        container.scrollLeft < paddingOffset
          ? paddingOffset - container.scrollLeft
          : 0
      const left = _.clamp(
        container.scrollLeft + (cardSize * 3 + offset),
        0,
        container.scrollWidth - container.clientWidth
      )
      gsap.to(container, { duration: 0.4, scrollLeft: left, ease: "power4" })
    }
  }

  React.useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", calculateScroll)
      window.addEventListener("resize", calculateScroll)
      calculateScroll()
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", calculateScroll)!
        window.removeEventListener("resize", calculateScroll)
      }
    }
  }, [containerRef])

  return (
    <Container>
      <Track ref={trackRef} />
      <Bar ref={barRef} />
      <Buttons>
        <Button onClick={onPrev}>
          <LeftArrow>
            <Arrow
              strokeColor={arrowColor ? arrowColor : colors.black}
              strokeWidth="1.5px"
            />
          </LeftArrow>
        </Button>
        <Button onClick={onNext}>
          <RightArrow>
            <Arrow
              strokeColor={arrowColor ? arrowColor : colors.black}
              strokeWidth="1.5px"
            />
          </RightArrow>
        </Button>
      </Buttons>
    </Container>
  )
}

export default Scrollbar
