import React from "react"
import styled, { css } from "styled-components"
import colors from "@constants/colors"
import { h6, body, bodyBold } from "@constants/typography"
import MaxWidthContainer from "@components/Layout/MaxWidthContainer"
import MaxWidthContent from "@components/Layout/MaxWidthContent"
import MarkdownIt from "markdown-it"
const Markdown = new MarkdownIt()

const Header = styled.h6<{ center?: boolean }>`
  ${({ center }) => css`
    ${h6}
    margin-bottom: 42px;
    color: ${colors.obsidian};
    text-align: ${center ? "center" : "left"};
  `}
`

const Body = styled.div<{ center?: boolean }>`
  ${({ center }) => css`
    ${body}
    margin-bottom: 42px;
    color: ${colors.slateDark};
    text-align: ${center ? "center" : "left"};

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    li {
      margin: 0;
      padding: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      ${h6}
      margin-bottom: 42px;
      color: ${colors.obsidian};

      &:not(:first-child) {
        margin-top: 84px;
      }

      img {
        width: 120%;
        margin: 0 -10%;
        display: block;
      }
    }

    strong {
      font-weight: 600;
    }

    em {
      font-style: italic;

      img {
        max-width: 50% !important;
        margin: auto;
        display: block;
      }
    }

    a {
      text-decoration-line: underline;
    }

    p {
      margin-bottom: 24px;

      img {
        max-width: 100%;
        display: block;
      }
    }

    ul {
      margin-bottom: 24px;
      list-style: none;

      img {
        max-width: 100%;
      }
    }

    li {
      padding-left: 0.5em;
    }

    ul:has(img) {
      display: flex;
      gap: 10px;
      margin: 10px 0;

      li {
        display: block;
        padding: 0;

        &::before {
          display: none;
        }
      }

      + hr {
        display: none;
      }
    }

    li:before {
      content: "-";
      display: inline-block;
      transform: translateX(-0.5em);
    }
  `}
`
const Table = styled.div`
  table {
    width: 100%;
    border: 1px solid ${colors.lightestGray};

    th,
    td {
      ${body}
      padding: 16px 8px;
    }

    th {
      text-align: left;
      ${bodyBold}
    }
  }
`

interface TextBlockProps {
  header?: string
  body: string
  centerHeader?: boolean
  centerBody?: boolean
}

const TextBlock: React.FC<TextBlockProps> = ({
  header,
  body,
  centerHeader,
  centerBody,
}) => {
  console.log(body)
  let table_array: any = null

  if (body.indexOf("-TABLE-") > 0) {
    const TABLE_START = body.indexOf("-TABLE-")
    const TABLE_END = body.indexOf("-END TABLE-")

    const table = body.substring(TABLE_START, TABLE_END)
    body =
      body.substring(0, TABLE_START) +
      body.substring(TABLE_END + 11, body.length)

    var rows = table.split("\n")
    rows.splice(0, 1)
    rows.splice(rows.length, 1)
    console.log(rows)

    table_array = Array()
    rows.forEach((row) => {
      table_array.push(row.split(","))
    })
    console.log(table_array)
  }

  var rows = body.split("\n")
  return (
    <MaxWidthContainer contentStyle={{ justifyContent: "center" }}>
      <MaxWidthContent cols={8}>
        {header && <Header center={centerHeader}>{header}</Header>}
        <Body
          center={centerBody}
          dangerouslySetInnerHTML={{ __html: Markdown.render(body) }}
        />

        {table_array ? (
          <Table>
            <table>
              {table_array.map((row, index) => (
                <tr>
                  {row.map((cell) =>
                    index == 0 ? <th>{cell}</th> : <td>{cell}</td>
                  )}
                </tr>
              ))}
            </table>
          </Table>
        ) : (
          ""
        )}
      </MaxWidthContent>
    </MaxWidthContainer>
  )
}

export default TextBlock
