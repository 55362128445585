import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import { columns14, columnsInMaxWidth } from "@constants/layout"
import MaxWidthContainer from "@components/Layout/MaxWidthContainer"
import MaxWidthContent from "@components/Layout/MaxWidthContent"
import _ from "lodash"
import Router from "next/router"

const Container = styled.div`
  align-items: center;
  justify-content: center;
  display: block;
  width: 100%;
  margin: auto;
  text-align: center;
`

interface CodeSnippetProps {
  code: string
}

let currentUrl = false

const onRouteChangeStart = (url) => {
  currentUrl = url
}

Router.events.on("routeChangeStart", onRouteChangeStart)

const CodeSnippet: React.FC<CodeSnippetProps> = ({ code }) => {
  const containerRef = React.useRef<HTMLDivElement>(null)

  const runScripts = (scripts) => {
    _.forEach(scripts, (script: HTMLScriptElement) => {
      const src = script.innerText
      const fn = new Function("require", src)
      fn()
    })
  }

  React.useEffect(() => {
    const scripts =
      containerRef &&
      containerRef.current &&
      containerRef.current.getElementsByTagName("script")

    // Moved by PSantos (causes script to fire twice)
    if (currentUrl) {
      runScripts(scripts)
    } else {
      runScripts(scripts)
    }
  }, [])

  return (
    <MaxWidthContainer contentStyle={{ justifyContent: "center" }}>
      <MaxWidthContent cols={10}>
        <Container
          ref={containerRef}
          dangerouslySetInnerHTML={{ __html: code }}
        />
      </MaxWidthContent>
    </MaxWidthContainer>
  )
}

export default CodeSnippet
