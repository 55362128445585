// import "react-responsive-carousel/lib/styles/carousel.min.css"
import React from "react"
import styled, { css } from "styled-components"
import { up, down } from "styled-breakpoints"
import { Carousel } from "react-responsive-carousel"
import colors from "@constants/colors"
import { columns } from "@constants/layout"
import {
  DESKTOP_HERO_HEIGHT,
  MOBILE_HERO_HEIGHT,
} from "@constants/measurements"
import { body, h6 } from "@constants/typography"
import { ChevronLarge } from "@components/icons"
import LinkButton, { StyledLink } from "@components/buttons/LinkButton"
import SquareIconButton from "@components/buttons/SquareIconButton"
import BackgroundImage, {
  Container as BackgroundImageContainer,
} from "@components/BackgroundImage"
import { DatoResponsiveImage } from "@dato/types"
import YmmSelector from "@components/YmmSelector"

const DesktopContainer = styled.div<{
  topPadding?: number
  hasYmm: boolean
}>`
  ${({ topPadding, hasYmm }) => css`
    position: relative;
    align-items: flex-end;
    display: none;
    width: 100%;
    height: ${DESKTOP_HERO_HEIGHT};

    ${up("laptop")} {
      display: flex;
      padding: ${hasYmm ? "0" : "0 " + columns(2) + " 72px"};

      [class*="HeroCarousel__ControlsRow"] {
        padding: 0 ${columns(2)} 200px;
      }

      ${BackgroundImageContainer} {
        justify-content: flex-start;
        height: ${DESKTOP_HERO_HEIGHT};
        padding: ${topPadding ? `${topPadding}vw` : "19vw"} 0 0;
      }
    }
  `}
`

const MobileContainer = styled(DesktopContainer)`
  display: flex;
  height: ${MOBILE_HERO_HEIGHT};
  padding: 20px 32px;

  ${BackgroundImageContainer} {
    height: ${MOBILE_HERO_HEIGHT};
  }

  ${up("laptop")} {
    display: none;
  }
`

const YMMcontainer = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: 162px;
  bottom: 0;
  left: 0;
  z-index: 2;

  ${down("tablet")} {
    height: auto;
  }
`

const ControlsRow = styled.div<{
  buttonColor?: string
  buttonTextColor?: string
}>`
  ${({ buttonColor, buttonTextColor }) => css`
    z-index: 2;
    justify-content: space-between;
    display: flex;
    width: 100%;

    ${StyledLink} {
      background-color: ${buttonColor ? `#${buttonColor}` : colors.snow};
      color: ${buttonTextColor ? `#${buttonTextColor}` : colors.obsidian};
    }
  `}
`

const ControlsGroup = styled.div`
  align-items: center;
  display: flex;
`

const HeroNavButton = styled(SquareIconButton)<{
  arrowBackgroundColor?: string
}>`
  ${({ arrowBackgroundColor }) => css`
    background-color: ${arrowBackgroundColor
      ? arrowBackgroundColor
      : "rgba(50, 57, 62, 1)"};

    :hover {
      background-color: ${arrowBackgroundColor
        ? arrowBackgroundColor
        : "rgba(255, 255, 255, 0.3)"};
    }
  `}
`
const MobileNavButton = styled(SquareIconButton)<{
  arrowBackgroundColor?: string
}>`
  ${({ arrowBackgroundColor }) => css`
    z-index: 2;
    position: absolute;
    top: calc(30% - 22px);
    height: 32px;
    width: 32px;
    padding: 8px;
    background-color: ${arrowBackgroundColor
      ? arrowBackgroundColor
      : "rgba(255, 255, 255, 0.2)"};

    :hover {
      background-color: ${arrowBackgroundColor
        ? arrowBackgroundColor
        : "rgba(255, 255, 255, 0.3)"};
    }
  `}
`

const TextContainer = styled.div`
  flex-direction: column;
  display: flex;
`

const Text = styled.p<{
  textColor?: string
  textSize?: number
  mobileTextSize?: number
}>`
  ${({ textColor, textSize, mobileTextSize }) => css`
    ${body};
    margin: 0;
    font-size: ${mobileTextSize ? `${mobileTextSize}px` : "15px"};
    line-height: 24px;
    color: ${textColor ? `#${textColor}` : colors.snow};

    ${up("laptop")} {
      font-size: ${textSize ? `${textSize}px` : "15px"};
    }
  `}
`

const StyledCarousel = styled(Carousel)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const MobileButton = styled.div<{
  buttonColor?: string
  buttonTextColor?: string
}>`
  ${({ buttonColor, buttonTextColor }) => css`
    z-index: 1;

    ${StyledLink} {
      z-index: 2;
      height: 42px;
      padding: 0 20px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      background-color: ${buttonColor ? `#${buttonColor}` : colors.snow};
      color: ${buttonTextColor ? `#${buttonTextColor}` : colors.obsidian};
    }
  `}
`

const HeadingOverlay = styled.h6<{ textColor?: string; textSize?: number }>`
  ${({ textColor, textSize }) => css`
    ${h6}
    font-size: ${textSize || 19}px;
    line-height: ${textSize || 24}px;
    color: ${textColor ? `#${textColor}` : colors.snow};
  `}
`

interface HeroCarouselProps {
  slides: {
    heroText: string
    heroTextColor?: string
    heroTextSize?: number
    heroMobileTextSize?: number
    heroTextTopPadding?: number
    title: string
    subtitle: string
    textColor?: string
    textSize?: number
    mobileTextSize?: number
    url?: string
    buttonColor?: string
    buttonText: string
    buttonTextColor?: string
    arrowBackgroundColor?: string
    backgroundImage: DatoResponsiveImage
    mobileBackgroundImage?: DatoResponsiveImage
  }[]
  interval?: number

  ymmAttachment?: {
    fontColor: string
    bgColor: string
  }
}

const HeroCarousel: React.FC<HeroCarouselProps> = ({
  slides,
  interval = 7000,
  ymmAttachment = undefined,
}) => {
  const [index, setIndex] = React.useState(0)
  const onChange = React.useCallback((i: number) => setIndex(i), [setIndex])
  const next = React.useCallback(
    () => setIndex((old) => (old + 1 === slides.length ? 0 : old + 1)),
    [setIndex]
  )
  const prev = React.useCallback(
    () => setIndex((old) => (old - 1 < 0 ? slides.length - 1 : old - 1)),
    [setIndex]
  )

  return (
    <>
      <DesktopContainer
        topPadding={slides[index].heroTextTopPadding}
        hasYmm={ymmAttachment ? true : false}
      >
        <StyledCarousel
          autoPlay={true}
          showThumbs={false}
          showIndicators={false}
          showArrows={false}
          selectedItem={index}
          showStatus={false}
          infiniteLoop={true}
          interval={interval}
          onChange={onChange}
        >
          {slides.map((slide) => (
            <BackgroundImage key={slide.heroText} image={slide.backgroundImage}>
              <HeadingOverlay
                textColor={slide.heroTextColor}
                textSize={slide.heroTextSize}
              >
                {slide.heroText}
              </HeadingOverlay>
            </BackgroundImage>
          ))}
        </StyledCarousel>
        <ControlsRow
          buttonColor={slides[index].buttonColor}
          buttonTextColor={slides[index].buttonTextColor}
        >
          <ControlsGroup>
            <HeroNavButton
              arrowBackgroundColor={slides[index].arrowBackgroundColor}
              style={{ marginRight: 24 }}
              onClick={prev}
            >
              <ChevronLarge
                style={{ transform: "rotate(180deg)" }}
                strokeColor={colors.snow}
                strokeWidth={2}
              />
            </HeroNavButton>
            <HeroNavButton
              arrowBackgroundColor={slides[index].arrowBackgroundColor}
              style={{ marginRight: 36 }}
              onClick={next}
            >
              <ChevronLarge strokeColor={colors.snow} strokeWidth={2} />
            </HeroNavButton>
            <TextContainer>
              <Text
                textColor={slides[index].textColor}
                textSize={slides[index].textSize}
                mobileTextSize={slides[index].mobileTextSize}
              >
                {slides[index].title}
              </Text>
              <Text
                textColor={slides[index].textColor}
                textSize={slides[index].textSize}
                mobileTextSize={slides[index].mobileTextSize}
                style={{ opacity: 0.5 }}
              >
                {slides[index].subtitle}
              </Text>
            </TextContainer>
          </ControlsGroup>
          {slides[index].url && (
            <LinkButton href={slides[index].url!}>
              {slides[index].buttonText}
            </LinkButton>
          )}
        </ControlsRow>

        {ymmAttachment ? (
          <YMMcontainer>
            <YmmSelector
              fontColor={ymmAttachment[0]?.fontColor}
              bgColor={ymmAttachment[0]?.bgColor}
            />
          </YMMcontainer>
        ) : (
          <></>
        )}
      </DesktopContainer>
      <MobileContainer hasYmm={ymmAttachment ? true : false}>
        <StyledCarousel
          autoPlay={true}
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          swipeable={false}
          infiniteLoop={true}
          interval={interval}
          onChange={onChange}
          renderArrowNext={(clickHandler) => (
            <MobileNavButton
              onClick={clickHandler}
              arrowBackgroundColor={slides[index].arrowBackgroundColor}
              style={{ right: 20 }}
            >
              <ChevronLarge strokeColor={colors.snow} strokeWidth={2} />
            </MobileNavButton>
          )}
          renderArrowPrev={(clickHandler) => (
            <MobileNavButton
              onClick={clickHandler}
              arrowBackgroundColor={slides[index].arrowBackgroundColor}
              style={{ left: 20 }}
            >
              <ChevronLarge
                style={{ transform: "rotate(180deg)" }}
                strokeColor={colors.snow}
                strokeWidth={2}
              />
            </MobileNavButton>
          )}
        >
          {slides.map((slide) => (
            <BackgroundImage
              key={slide.heroText}
              image={
                slide.mobileBackgroundImage
                  ? slide.mobileBackgroundImage
                  : slide.backgroundImage
              }
            >
              <HeadingOverlay
                textColor={slide.heroTextColor}
                textSize={slide.heroMobileTextSize}
                style={{ margin: "0 84px" }}
              >
                {slide.heroText}
              </HeadingOverlay>
            </BackgroundImage>
          ))}
        </StyledCarousel>
        {slides[index].url && (
          <MobileButton
            buttonColor={slides[index].buttonColor}
            buttonTextColor={slides[index].buttonTextColor}
          >
            <LinkButton href={slides[index].url!}>
              {slides[index].buttonText}
            </LinkButton>
          </MobileButton>
        )}

        {ymmAttachment ? (
          <YMMcontainer>
            <YmmSelector
              fontColor={ymmAttachment[0]?.fontColor}
              bgColor={ymmAttachment[0]?.bgColor}
            />
          </YMMcontainer>
        ) : (
          <></>
        )}
      </MobileContainer>
    </>
  )
}

export default HeroCarousel
