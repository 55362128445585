import React from "react"
import { DatoContentBaseProps } from "../../types"
import CardSlider from "@components/CardSlider"

const formatCard = ({ link, title, subtitle, image, fillContainer }) => ({
  link,
  title,
  subtitle,
  image: image?.responsiveImage,
  fillContainer,
})

interface DatoCmsComponentCardSliderProps extends DatoContentBaseProps {}

const DatoCmsComponentCardSlider: React.FC<DatoCmsComponentCardSliderProps> = ({
  header,
  ctaLink,
  ctaButtonText,
  ctaButtonColor,
  ctaButtonTextColor,
  cards,
}) => {
  return (
    <CardSlider
      header={header}
      ctaLink={ctaLink}
      ctaButtonText={ctaButtonText}
      ctaButtonColor={ctaButtonColor}
      ctaButtonTextColor={ctaButtonTextColor}
      cards={cards.map(formatCard)}
    />
  )
}

export default DatoCmsComponentCardSlider
