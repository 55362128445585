import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import Block, { BaseCTABlockProps } from "./Block"

const Container = styled.div`
  flex-direction: column;
  display: flex;

  ${up("laptop")} {
    flex-direction: row;
  }
`

const BlockWrapper = styled.div<{ halfWidth?: boolean }>`
  ${({ halfWidth }) => css`
    display: flex;
    width: 100%;

    ${up("laptop")} {
      width: ${halfWidth ? "50vw" : "100vw"};
    }
  `}
`

interface InfoBlockProps {
  blocks: [BaseCTABlockProps, BaseCTABlockProps?]
}

const InfoBlock: React.FC<InfoBlockProps> = ({ blocks }) => {
  return (
    <Container>
      <BlockWrapper halfWidth={blocks.length === 2}>
        <Block halfWidth={blocks.length === 2} {...blocks[0]} />
      </BlockWrapper>
      {blocks[1] && (
        <BlockWrapper halfWidth>
          <Block halfWidth {...blocks[1]} />
        </BlockWrapper>
      )}
    </Container>
  )
}

export default InfoBlock
