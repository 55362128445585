import React from "react"
import styled, { css } from "styled-components"
import { up, down } from "styled-breakpoints"
import { Image } from "react-datocms"
import MaxWidthContainer, {
  Content,
} from "@components/Layout/MaxWidthContainer"
import MaxWidthContent from "@components/Layout/MaxWidthContent"
import { DatoResponsiveImage } from "@dato/types"
import { MobileOnly, LaptopUpOnly } from "@utils/MediaQueries"

const Container = styled.div`
  width: 100vw;
`

const StyledMaxWidthContainer = styled(MaxWidthContainer)`
  ${down("laptop")} {
    ${Content} {
      padding: 0;
    }
  }
`

const ImageWrapper = styled.div<{
  height?: number
  mobileHeight?: number
  autoHeight?: boolean
}>`
  ${({ height, mobileHeight, autoHeight }) => css`
    height: ${autoHeight
      ? "auto"
      : mobileHeight
      ? `${mobileHeight}px`
      : "calc(100vw * 0.66)"};
    overflow: hidden;

    ${up("laptop")} {
      height: ${autoHeight
        ? "auto"
        : height
        ? `${height}px`
        : "calc(100vw * 0.4)"};
    }

    .DatoImage {
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }
  `}
`

interface ImageProps {
  image: DatoResponsiveImage
  mobileImage?: DatoResponsiveImage
  fullWidth?: boolean
  height?: number
  mobileHeight?: number
  autoHeight?: boolean
}

const Block: React.FC<ImageProps> = ({
  image,
  mobileImage,
  fullWidth,
  height,
  mobileHeight,
  autoHeight,
}) => {
  const ImageComponent = (
    <ImageWrapper
      height={height}
      mobileHeight={mobileHeight}
      autoHeight={autoHeight}
    >
      <MobileOnly>
        <Image data={mobileImage || image} className="DatoImage" />
      </MobileOnly>
      <LaptopUpOnly>
        <Image data={image} className="DatoImage" />
      </LaptopUpOnly>
    </ImageWrapper>
  )

  return fullWidth ? (
    <Container>{ImageComponent}</Container>
  ) : (
    <StyledMaxWidthContainer>
      <MaxWidthContent cols={12}>{ImageComponent}</MaxWidthContent>
    </StyledMaxWidthContainer>
  )
}

export default Block
