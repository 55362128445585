import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import Link from "next/link"
import { Image } from "react-datocms"
import colors from "@constants/colors"
import { columns, columns14, columnsInMaxWidth } from "@constants/layout"
import { body, bodyBold, h6 } from "@constants/typography"
import Button from "@components/buttons/Button"
import Scrollbar, {
  Container as ScrollbarContainer,
} from "@components/Scrollbar"
import { DatoResponsiveImage } from "@dato/types"

const Container = styled.div<any>`
  flex-direction: column;
  display: flex;
  width: 100%;

  ${ScrollbarContainer} {
    padding: 0 20px;
  }

  ${up("laptop")} {
    ${ScrollbarContainer} {
      padding: 0 ${columns14(1)};
    }
  }

  ${up("maxWidth")} {
    ${ScrollbarContainer} {
      padding: 0 ${columns(2)};
    }
  }
`

const Row = styled.div<{
  buttonColor?: string
  buttonTextColor?: string
}>`
  ${({ buttonColor, buttonTextColor }) => css`
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin-bottom: 32px;
    padding: 0 20px;

    ${up("laptop")} {
      margin-bottom: 26px;
      padding: 0 ${columns14(1)};
    }

    ${up("maxWidth")} {
      padding: 0 ${columns(2)};
    }

    ${Button} {
      padding: 0 20px;
      background-color: ${buttonColor ? `#${buttonColor}` : colors.primary};
      color: ${buttonTextColor ? `#${buttonTextColor}` : colors.snow};
    }

    ${up("laptop")} {
      ${Button} {
        width: auto;
      }
    }
  `}
`

const Header = styled.div`
  ${h6}
  flex-shrink: 0;
  display: flex;
  width: 70%;

  ${up("laptop")} {
    width: initial;
    font-size: 22px;
    line-height: 65px;
  }
`

const Content = styled.div`
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
  margin-bottom: -20px;
  padding: 0 20px 52px;

  ${up("laptop")} {
    padding: 0 ${columns14(1)} 98px;
  }

  ${up("maxWidth")} {
    padding: 0 ${columns(2)} 98px;
  }
`

const Block = styled.div``

const CardWrapper = styled.a<{ clickable?: boolean }>`
  ${({ clickable }) => css`
    flex-direction: row;
    flex-shrink: 0;
    display: inline-flex;
    height: 100%;
    ${clickable ? "pointer-events: all;" : "cursor: default;"}
  `}
`

const Card = styled.div`
  flex-direction: column;
  display: flex;
  width: 68vw;
  border: solid 1px ${colors.divider};
  background-color: ${colors.divider};

  ${up("laptop")} {
    width: ${columns14(3.75)};
  }

  ${up("maxWidth")} {
    width: calc(${columnsInMaxWidth(3.75)});
  }
`

const ImageWrapper = styled.div<{ fillContainer?: boolean }>`
  ${({ fillContainer }) => css`
    height: 68vw;

    ${up("laptop")} {
      height: ${columns14(3.75)};
    }

    ${up("maxWidth")} {
      height: calc(${columnsInMaxWidth(3.75)});
    }

    .DatoImage {
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: ${fillContainer ? "none" : "contain"};
        object-position: center center;
      }
    }
  `}
`

const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.divider};
`

const Body = styled.div`
  padding: 20px;

  ${up("laptop")} {
    padding: 24px 20px 32px;
  }
`

const TextBold = styled.div`
  ${bodyBold}
  margin-bottom: 8px;
  color: ${colors.dark};
`

const Text = styled.div`
  ${body}
  opacity: 0.5;
  color: ${colors.dark};
`

const Spacer = styled.div`
  flex-shrink: 0;
  width: 12px;
  height: 100%;
  background: transparent;

  ${up("laptop")} {
    width: 20px;
  }
`

const FinalSpacer = styled.div`
  flex-shrink: 0;
  width: 20px;
  height: 100%;
  background: transparent;

  ${up("laptop")} {
    width: ${columns14(1)};
  }

  ${up("maxWidth")} {
    width: ${columns(2)};
  }
`

// mobile Safari PLEASE
const MobileSafariScrollbarHider = styled.div`
  z-index: 10;
  width: 100%;
  height: 20px;
  background-color: ${colors.snow};
`

interface CardProps {
  link?: string
  title: string
  subtitle: string
  image?: DatoResponsiveImage
  fillContainer?: boolean
}

interface CardSliderProps {
  header: string
  ctaLink?: string
  ctaButtonText?: string
  ctaButtonColor?: string
  ctaButtonTextColor?: string
  cards: Array<CardProps>
}

const renderCard = (card, i, cardsLength) => (
  <CardWrapper clickable={!!card.link}>
    <Card>
      <ImageWrapper fillContainer={card.fillContainer}>
        {card.image ? (
          <Image data={card.image} className="DatoImage" />
        ) : (
          <Placeholder />
        )}
      </ImageWrapper>
      <Body>
        <TextBold>{card.title}</TextBold>
        <Text>{card.subtitle}</Text>
      </Body>
    </Card>
    {i === cardsLength - 1 ? <FinalSpacer /> : <Spacer />}
  </CardWrapper>
)

const renderLink = (card, i, cardsLength) => (
  <Link key={`${i}-${card.link}`} href={card.link} passHref>
    {renderCard(card, i, cardsLength)}
  </Link>
)

const renderBlock = (card, i, cardsLength) => (
  <Block key={`${i}-${card.link}`}>{renderCard(card, i, cardsLength)}</Block>
)

const CardSlider: React.FC<CardSliderProps> = ({
  header,
  ctaLink,
  ctaButtonText,
  ctaButtonColor,
  ctaButtonTextColor,
  cards,
}) => {
  const contentRef = React.createRef<HTMLDivElement>()

  return (
    <Container>
      <Row buttonColor={ctaButtonColor} buttonTextColor={ctaButtonTextColor}>
        <Header>{header}</Header>
        {ctaLink && ctaButtonText && (
          <Link href={ctaLink} passHref>
            <Button>{ctaButtonText}</Button>
          </Link>
        )}
      </Row>
      <Content ref={contentRef}>
        {cards.map((card, i) =>
          card.link
            ? renderLink(card, i, cards.length)
            : renderBlock(card, i, cards.length)
        )}
        <FinalSpacer />
      </Content>
      <Scrollbar containerRef={contentRef} />
      <MobileSafariScrollbarHider />
    </Container>
  )
}

export default CardSlider
