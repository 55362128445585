import React from "react"
import { DatoContentBaseProps } from "../../types"
import VerticalSpace from "@components/VerticalSpace"

interface DatoCmsComponentVerticalSpaceProps extends DatoContentBaseProps {}

const DatoCmsComponentVerticalSpace: React.FC<DatoCmsComponentVerticalSpaceProps> = ({
  height,
  heightString,
  mobileHeight,
  mobileHeightString,
}) => {
  return (
    <VerticalSpace
      height={height}
      heightString={heightString}
      mobileHeight={mobileHeight}
      mobileHeightString={mobileHeightString}
    />
  )
}

export default DatoCmsComponentVerticalSpace
