import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import colors from "@constants/colors"
import { h3, h4 } from "@constants/typography"
import MaxWidthContainer from "@components/Layout/MaxWidthContainer"
import MaxWidthContent from "@components/Layout/MaxWidthContent"
import { MobileOnly, LaptopUpOnly } from "@utils/MediaQueries"

const Container = styled.div<{
  topPadding: number
  topPaddingMobile: number
  bottomPadding: number
  bottomPaddingMobile: number
}>`
  ${({
    topPadding,
    topPaddingMobile,
    bottomPadding,
    bottomPaddingMobile,
  }) => css`
    padding: ${topPaddingMobile}px 0 ${bottomPaddingMobile}px 0;

    ${up("laptop")} {
      padding: ${topPadding}px 0 ${bottomPadding}px 0;
    }
  `}
`

const Header = styled.h1`
  ${h4}
  text-align: left;
  color: ${colors.obsidian};

  ${up("laptop")} {
    ${h3}
    text-align: center;
    color: ${colors.black};
  }
`

interface TextHeroProps {
  text: string
  mobileText?: string
  topPadding: number
  topPaddingMobile: number
  bottomPadding: number
  bottomPaddingMobile: number
}

const TextHero: React.FC<TextHeroProps> = ({
  text,
  mobileText,
  topPadding,
  topPaddingMobile,
  bottomPadding,
  bottomPaddingMobile,
}) => {
  return (
    <MaxWidthContainer contentStyle={{ justifyContent: "center" }}>
      <MaxWidthContent cols={8}>
        <Container
          topPadding={topPadding}
          topPaddingMobile={topPaddingMobile}
          bottomPadding={bottomPadding}
          bottomPaddingMobile={bottomPaddingMobile}
        >
          <MobileOnly>
            <Header>{mobileText || text}</Header>
          </MobileOnly>
          <LaptopUpOnly>
            <Header>{text}</Header>
          </LaptopUpOnly>
        </Container>
      </MaxWidthContent>
    </MaxWidthContainer>
  )
}

export default TextHero
