import React from "react"
import { Image } from "react-datocms"
import MaxWidthContainer from "@components/Layout/MaxWidthContainer"
import MaxWidthContent from "@components/Layout/MaxWidthContent"
import Masonry from "@components/Masonry"
import { DatoResponsiveImage } from "@dato/types"

interface ImageProps {
  images: Array<DatoResponsiveImage>
  standardizeImageSizes?: boolean
}

const Block: React.FC<ImageProps> = ({
  images,
  standardizeImageSizes = false,
}) => {
  return (
    <MaxWidthContainer contentStyle={{ justifyContent: "center" }}>
      <MaxWidthContent cols={8}>
        <Masonry
          mobileSpacing="20px"
          standardizeImageSizes={standardizeImageSizes}
        >
          {images.map((image, i) => (
            <Image
              key={`${i}-${image.src}`}
              data={image}
              className="DatoImage"
            />
          ))}
        </Masonry>
      </MaxWidthContent>
    </MaxWidthContainer>
  )
}

export default Block
