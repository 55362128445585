import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import colors from "@constants/colors"
import { body, h6, smallcaps } from "@constants/typography"
import MaxWidthContainer from "@components/Layout/MaxWidthContainer"
import MaxWidthContent from "@components/Layout/MaxWidthContent"

interface FeatureListProps {
  header: string
  features: Array<{
    label: string
    items: string
  }>
}

const Header = styled.h6`
  ${h6}
  width: 100%;
  margin-bottom: 48px;

  ${up("laptop")} {
    margin-bottom: 82px;
  }
`

const FeaturesContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
`

const FeatureGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
`

const FeatureLabel = styled.div`
  ${smallcaps}
  margin-bottom: 12px;

  ${up("laptop")} {
    margin-bottom: 16px;
  }
`

const FeatureItem = styled.div`
  ${body}
  color: ${colors.slateDark};
`

const FeatureList: React.FC<FeatureListProps> = ({ header, features }) => {
  return (
    <MaxWidthContainer contentStyle={{ justifyContent: "center" }}>
      <MaxWidthContent cols={8}>
        <Header>{header}</Header>
        <FeaturesContainer>
          {features.map((feature) => (
            <FeatureGroup key={feature.label}>
              <FeatureLabel>{feature.label}</FeatureLabel>
              {feature.items.split(",").map((item) => (
                <FeatureItem key={item.trim()}>{item.trim()}</FeatureItem>
              ))}
            </FeatureGroup>
          ))}
        </FeaturesContainer>
      </MaxWidthContent>
    </MaxWidthContainer>
  )
}

export default FeatureList
