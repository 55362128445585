import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import { useRouter } from "next/router"
import colors from "@constants/colors"
import { columns14, columnsInMaxWidth } from "@constants/layout"
import { bodyBold } from "@constants/typography"
import MaxWidthContainer from "@components/Layout/MaxWidthContainer"
import MaxWidthContent from "@components/Layout/MaxWidthContent"
import SelectInput, {
  Container as SelectInputContainer,
} from "@components/inputs/SelectInput"
import {
  getCarsMakeModelYearUrl,
  getCarsMakeModelYearTrimUrl,
} from "@utils/urls"
import _ from "lodash"

const Container = styled.div`
  display: flex;
  padding: 12px;
  background-color: ${colors.silver};

  ${up("laptop")} {
    width: 100%;
    padding: 44px ${columns14(1)};
  }

  ${up("maxWidth")} {
    padding: 44px ${columnsInMaxWidth(1)};
  }

  ${SelectInputContainer} {
    background-color: ${colors.snow};
  }
`

const Content = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
`

const Filter = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) => css`
    flex-grow: 1;
    margin: 0 8px;
    pointer-events: ${disabled ? "none" : "all"};
  `}
`

const Label = styled.div`
  ${bodyBold}
  margin-right: 55px;
  color: ${colors.obsidian};
`

export interface YearSelectorProps {
  car: {
    make: {
      name: string
      slug: string
    }
    model: {
      name: string
      slug: string
    }
    trim?: {
      name: string
      slug: string
    }
    years: string
  }
}

const CarSelectorBanner: React.FC<YearSelectorProps> = ({ car }) => {
  const router = useRouter()

  const years = car
    ? car.years
        .split(", ")
        .map((year) => year.trim())
        .map((year) => ({
          value: year,
          label: year,
        }))
    : []

  return (
    <MaxWidthContainer contentStyle={{ justifyContent: "center" }}>
      <MaxWidthContent cols={12}>
        <Container>
          <Content>
            <Label>{`${years[0].value}-${years[years.length - 1].value} ${
              car.make.name
            } ${car.model.name}${
              car.trim?.name ? ` ${car.trim?.name}` : ""
            }`}</Label>
            <Filter>
              <SelectInput
                name={"Year"}
                label={"Year"}
                value={"Select Year"}
                options={years}
                onChange={(e) => {
                  const url = car.trim
                    ? getCarsMakeModelYearTrimUrl(
                        car.make.slug,
                        car.model.slug,
                        e.target.value,
                        car.trim.slug
                      )
                    : getCarsMakeModelYearUrl(
                        car.make.slug,
                        car.model.slug,
                        e.target.value
                      )
                  router.push(url)
                }}
              />
            </Filter>
          </Content>
        </Container>
      </MaxWidthContent>
    </MaxWidthContainer>
  )
}

export default CarSelectorBanner
