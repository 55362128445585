import React from "react"
import { DatoContentBaseProps } from "../../types"
import ImageGrid from "@components/ImageGrid"

interface DatoCmsComponentImageGridProps extends DatoContentBaseProps {}

const DatoCmsComponentImageGrid: React.FC<DatoCmsComponentImageGridProps> = ({
  images,
  standardizeImageSizes,
}) => {
  return (
    <ImageGrid
      images={images.map(({ responsiveImage }) => responsiveImage)}
      standardizeImageSizes={standardizeImageSizes}
    />
  )
}

export default DatoCmsComponentImageGrid
