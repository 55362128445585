import React from "react"
import { DatoContentBaseProps } from "../../types"
import Image from "@components/Image"

interface DatoCmsComponentImageProps extends DatoContentBaseProps {}

const DatoCmsComponentImage: React.FC<DatoCmsComponentImageProps> = ({
  image,
  mobileImage,
  fullWidth,
  height,
  mobileHeight,
  autoHeight,
}) => {
  return (
    <Image
      image={image.responsiveImage}
      mobileImage={mobileImage?.responsiveImage}
      fullWidth={fullWidth}
      height={height}
      mobileHeight={mobileHeight}
      autoHeight={autoHeight}
    />
  )
}

export default DatoCmsComponentImage
