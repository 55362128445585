import React from "react"
import { DatoContentBaseProps } from "../../types"
import YmmSelector from "@components/YmmSelector"

interface DatoCmsComponentYmmSelectorProps extends DatoContentBaseProps {}

const DatoCmsComponentYmmSelector: React.FC<
  DatoCmsComponentYmmSelectorProps
> = ({ fontColor, bgColor }) => {
  return <YmmSelector fontColor={fontColor} bgColor={bgColor} />
}

export default DatoCmsComponentYmmSelector
