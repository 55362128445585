import React from "react"
import { DatoContentBaseProps } from "../../types"
import YearSelector from "@components/YearSelector"

interface DatoCmsComponentYearSelectorProps extends DatoContentBaseProps {}

const DatoCmsComponentYearSelector: React.FC<DatoCmsComponentYearSelectorProps> = ({
  car,
}) => {
  return <YearSelector car={car} />
}

export default DatoCmsComponentYearSelector
