import React from "react"
import { DatoContentBaseProps } from "../../types"
import Video from "@components/Video"

interface DatoCmsComponentVideoProps extends DatoContentBaseProps {}

const DatoCmsComponentVideo: React.FC<DatoCmsComponentVideoProps> = ({
  embedId,
}) => {
  return <Video url={`https://www.youtube.com/embed/${embedId}`} />
}

export default DatoCmsComponentVideo
