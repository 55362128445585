import React from "react"
import { DatoContentBaseProps } from "../../types"
import PageHeader from "@components/PageHeader"

interface DatoCmsComponentPageHeaderProps extends DatoContentBaseProps {}

const DatoCmsComponentPageHeader: React.FC<DatoCmsComponentPageHeaderProps> = ({
  heroText,
  backgroundImage,
  mobileImage,
  textAlignment,
  textColor,
  textBgColor,
  ymmAttachment,
}) => {
  return (
    <PageHeader
      heroText={heroText}
      backgroundImage={backgroundImage.responsiveImage}
      mobileImage={mobileImage.responsiveImage}
      textAlignment={textAlignment}
      textColor={textColor}
      textBgColor={textBgColor}
      ymmAttachment={ymmAttachment}
    />
  )
}

export default DatoCmsComponentPageHeader
