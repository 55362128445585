import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import colors from "@constants/colors"
import { columns } from "@constants/layout"
import Block, { BaseInfoBlockProps } from "./Block"

const Container = styled.div`
  flex-direction: column;
  display: flex;

  ${up("laptop")} {
    flex-direction: row;
  }
`

const BlockWrapper = styled.div`
  display: flex;

  ${up("laptop")} {
    width: ${columns(8)};
  }
`

interface InfoBlockProps {
  blocks: [BaseInfoBlockProps, BaseInfoBlockProps]
}

const InfoBlock: React.FC<InfoBlockProps> = ({ blocks }) => {
  return (
    <Container>
      <BlockWrapper>
        <Block
          {...{
            ...blocks[0],
            backgroundColor: colors.lightestGray,
          }}
        />
      </BlockWrapper>
      <BlockWrapper>
        <Block
          {...{
            ...blocks[1],
            backgroundColor: colors.silver,
          }}
        />
      </BlockWrapper>
    </Container>
  )
}

export default InfoBlock
