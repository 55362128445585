import React from "react"
import styled from "styled-components"
import { up, down } from "styled-breakpoints"
import MaxWidthContainer, {
  Content,
} from "@components/Layout/MaxWidthContainer"
import MaxWidthContent from "@components/Layout/MaxWidthContent"

const StyledMaxWidthContainer = styled(MaxWidthContainer)`
  ${down("laptop")} {
    ${Content} {
      padding: 0;
    }
  }
`

const Padding = styled.div`
  padding-bottom: 50%;
`

const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`

interface VideoProps {
  url: string
}

const Video: React.FC<VideoProps> = ({ url }) => {
  return (
    <StyledMaxWidthContainer>
      <MaxWidthContent cols={12} style={{ position: "relative" }}>
        <Padding />
        <IFrame
          src={url}
          allow="autoplay; accelerometer; encrypted-media; fullscreen; gyroscope; picture-in-picture"
        />
      </MaxWidthContent>
    </StyledMaxWidthContainer>
  )
}

export default Video
