import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import colors from "@constants/colors"
import * as fonts from "@constants/typography"
import MaxWidthContainer from "@components/Layout/MaxWidthContainer"
import MaxWidthContent from "@components/Layout/MaxWidthContent"
import BackgroundImage, {
  Container as BackgroundImageContainer,
} from "@components/BackgroundImage"
import { DatoResponsiveImage } from "@dato/types"

const Container = styled.div`
  ${BackgroundImageContainer} {
    height: calc(100vw * 1.5);

    ${up("laptop")} {
      height: calc(100vw * 0.4);
    }
  }
`

const Header = styled.h4<{
  fontSize?: number
  mobileFontSize?: number
  useTextBackgroundOverlay?: boolean
}>`
  ${({ fontSize, mobileFontSize, useTextBackgroundOverlay }) => css`
    ${fonts.bodyBold}
    padding: 0 8px;
    background-color: ${useTextBackgroundOverlay ? colors.divider30 : "none"};
    font-size: ${mobileFontSize ? mobileFontSize : 15}px;
    text-align: center;
    color: ${colors.snow};

    ${up("laptop")} {
      ${fonts.h5}
      margin: 0;
      padding: 0;
      font-size: ${fontSize ? fontSize : 24}px;
      color: ${colors.snow};
    }
  `}
`

const Subheader = styled.div<{
  fontSize?: number
  mobileFontSize?: number
  subheaderFontStyle?: SubheaderFontStyles
  useTextBackgroundOverlay?: boolean
}>`
  ${({
    fontSize,
    mobileFontSize,
    subheaderFontStyle,
    useTextBackgroundOverlay,
  }) => css`
    ${fonts[
      subheaderFontStyle ? SubheaderFontStyles[subheaderFontStyle] : "smallcaps"
    ]}
    margin: 12px 0;
    background-color: ${useTextBackgroundOverlay ? colors.divider30 : "none"};
    font-size: ${mobileFontSize ? mobileFontSize : 12}px;
    text-align: center;
    color: ${colors.snow};

    ${up("laptop")} {
      margin-bottom: 32px;
      font-size: ${fontSize ? fontSize : 12}px;
    }
  `}
`

enum SubheaderFontStyles {
  "Body" = "body",
  "Body Bold" = "bodyBold",
  "Smallcaps" = "smallcaps",
}

interface HeroProps {
  backgroundImage: DatoResponsiveImage
  header?: string
  headerFontSize?: number
  headerMobileFontSize?: number
  subheader?: string
  subheaderFontSize?: number
  subheaderMobileFontSize?: number
  subheaderFontStyle?: SubheaderFontStyles | undefined
  swapHeaderPositions?: boolean
  useTextBackgroundOverlay?: boolean
}

const Hero: React.FC<HeroProps> = ({
  backgroundImage,
  header,
  headerFontSize,
  headerMobileFontSize,
  subheader,
  subheaderFontSize,
  subheaderMobileFontSize,
  subheaderFontStyle,
  swapHeaderPositions,
  useTextBackgroundOverlay,
}) => {
  return (
    <Container>
      <BackgroundImage image={backgroundImage}>
        <MaxWidthContainer contentStyle={{ justifyContent: "center" }}>
          <MaxWidthContent cols={8}>
            {!swapHeaderPositions && subheader && (
              <Subheader
                fontSize={subheaderFontSize}
                mobileFontSize={subheaderMobileFontSize}
                subheaderFontStyle={subheaderFontStyle}
                useTextBackgroundOverlay={useTextBackgroundOverlay}
              >
                {subheader}
              </Subheader>
            )}
            {header && (
              <Header
                fontSize={headerFontSize}
                mobileFontSize={headerMobileFontSize}
                useTextBackgroundOverlay={useTextBackgroundOverlay}
              >
                {header}
              </Header>
            )}
            {swapHeaderPositions && subheader && (
              <Subheader
                fontSize={subheaderFontSize}
                mobileFontSize={subheaderMobileFontSize}
                subheaderFontStyle={subheaderFontStyle}
                useTextBackgroundOverlay={useTextBackgroundOverlay}
              >
                {subheader}
              </Subheader>
            )}
          </MaxWidthContent>
        </MaxWidthContainer>
      </BackgroundImage>
    </Container>
  )
}

export default Hero
