import React from "react"
import { DatoContentBaseProps } from "../../types"
import InfoBlock from "@components/InfoBlock"

interface DatoCmsComponentInfoBlockProps extends DatoContentBaseProps {}

const DatoCmsComponentInfoBlock: React.FC<DatoCmsComponentInfoBlockProps> = ({
  blocks,
}) => {
  return <InfoBlock blocks={blocks} />
}

export default DatoCmsComponentInfoBlock
