import React from "react"
import styled from "styled-components"
import colors from "@constants/colors"
import { body } from "@constants/typography"
import MaxWidthContainer from "@components/Layout/MaxWidthContainer"
import MaxWidthContent from "@components/Layout/MaxWidthContent"
import Button from "@components/buttons/Button"

const Link = styled.a`
  ${body}
  margin-bottom: 24px;
  color: ${colors.obsidian};
  text-decoration: none;

  button {
    width: auto;
    min-width: 228px;
    padding: 0 24px;
  }
`

interface DownloadProps {
  download: string
  text: string
}

const Download: React.FC<DownloadProps> = ({ download, text }) => {
  return (
    <MaxWidthContainer contentStyle={{ justifyContent: "center" }}>
      <MaxWidthContent
        cols={8}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Link href={download}>
          <Button>{text}</Button>
        </Link>
      </MaxWidthContent>
    </MaxWidthContainer>
  )
}

export default Download
