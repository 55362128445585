import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import colors from "@constants/colors"
import { columns } from "@constants/layout"
import { h5 } from "@constants/typography"
import LinkButton, { StyledLink } from "@components/buttons/LinkButton"
import BackgroundImage, {
  Container as BackgroundImageContainer,
} from "@components/BackgroundImage"
import { DatoResponsiveImage } from "@dato/types"

const Container = styled.div<{ halfWidth?: boolean }>`
  ${({ halfWidth }) => css`
    width: 100%;

    ${BackgroundImageContainer} {
      justify-content: space-between;
      height: ${halfWidth ? "calc(100vw * 1.25)" : "calc(100vw * 1.5)"};
      padding: ${halfWidth ? "52vw" : "62vw"} 20px 32px;
    }

    ${up("laptop")} {
      ${BackgroundImageContainer} {
        height: ${halfWidth ? "50vw" : "40vw"};
        padding: ${halfWidth ? "24vw" : "18vw"} 0 80px;
      }

      ${StyledLink} {
        width: initial;
      }
    }
  `}
`

const Heading = styled.h4<{ halfWidth?: boolean }>`
  ${({ halfWidth }) => css`
    ${h5}
    margin: 0;
    padding: 0 56px;
    text-align: center;
    color: ${colors.snow};

    ${up("laptop")} {
      padding: 0 ${halfWidth ? columns(2) : columns(4)};
      font-size: 28px;
      line-height: 36px;
    }
  `}
`

const Spacer = styled.div`
  height: 44px;
`

export interface BaseCTABlockProps {
  headline: string
  url?: string
  buttonText: string
  backgroundImage: DatoResponsiveImage
  style?: React.CSSProperties
}

interface ExtendedCTABlockProps extends BaseCTABlockProps {
  halfWidth?: boolean
}

const CTABlock: React.FC<ExtendedCTABlockProps> = ({
  headline,
  url,
  buttonText,
  backgroundImage,
  halfWidth,
  style,
}) => {
  return (
    <Container halfWidth={halfWidth} style={style}>
      <BackgroundImage image={backgroundImage}>
        <Heading halfWidth={halfWidth}>{headline}</Heading>
        {url ? <LinkButton href={url}>{buttonText}</LinkButton> : <Spacer />}
      </BackgroundImage>
    </Container>
  )
}

export default CTABlock
