import React from "react"
import { DatoContentBaseProps } from "../../types"
import CodeSnippet from "@components/CodeSnippet"

interface DatoCmsComponentCodeSnippetProps extends DatoContentBaseProps {}

const DatoCmsComponentCodeSnippet: React.FC<DatoCmsComponentCodeSnippetProps> = ({
  code,
}) => {
  return <CodeSnippet code={code} />
}

export default DatoCmsComponentCodeSnippet
