import React from "react"
import { DatoContentBaseProps } from "../../types"
import ProductSlider from "@components/ProductSlider"

const formatCard = ({ slug, productName, price, thumbnail }) => ({
  slug,
  productName,
  price,
  thumbnail: thumbnail?.responsiveImage,
})

interface DatoCmsComponentProductSliderProps extends DatoContentBaseProps {}

const DatoCmsComponentProductSlider: React.FC<
  DatoCmsComponentProductSliderProps
> = ({ header, ctaLink, ctaButtonText, products }) => {
  return (
    <ProductSlider
      header={header}
      ctaLink={ctaLink}
      ctaButtonText={ctaButtonText}
      products={products.map(formatCard)}
    />
  )
}

export default DatoCmsComponentProductSlider
