import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import ReactMasonry from "react-masonry-css"

const Container = styled.div<{ spacing: string; mobileSpacing: string }>`
  ${({ spacing, mobileSpacing }) => css`
    .masonry-grid {
      display: flex;
      width: auto;
      margin-left: -${mobileSpacing};
    }

    .masonry-grid__column {
      padding-left: ${mobileSpacing};
      background-clip: padding-box;
    }

    .masonry-grid__column > div {
      margin-bottom: ${mobileSpacing};
    }

    ${up("laptop")} {
      .masonry-grid {
        margin-left: -${spacing};
      }

      .masonry-grid__column {
        padding-left: ${spacing};
      }

      .masonry-grid__column > div {
        margin-bottom: ${spacing};
      }
    }

    .DatoImage {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }
  `}
`

interface MasonryProps {
  breakpointCols?: number | { [key: number]: number; default: number }
  spacing?: string
  mobileSpacing?: string
  standardizeImageSizes?: boolean
}

const Masonry: React.FC<MasonryProps> = ({
  children,
  breakpointCols = { default: 2 },
  spacing = "24px",
  mobileSpacing = "24px",
  standardizeImageSizes = false,
}) => {
  return (
    <Container spacing={spacing} mobileSpacing={mobileSpacing}>
      <ReactMasonry
        breakpointCols={breakpointCols}
        className={
          "masonry-grid" + (standardizeImageSizes ? " standardize-images" : "")
        }
        columnClassName="masonry-grid__column"
      >
        {children}
      </ReactMasonry>
    </Container>
  )
}

export default Masonry
