import React from "react"
import { DatoContentBaseProps } from "../../types"
import TextHero from "@components/TextHero"

interface DatoCmsComponentTextHeroProps extends DatoContentBaseProps {}

const DatoCmsComponentTextHero: React.FC<DatoCmsComponentTextHeroProps> = ({
  text,
  mobileText,
  topPadding,
  topPaddingMobile,
  bottomPadding,
  bottomPaddingMobile,
}) => {
  return (
    <TextHero
      text={text}
      mobileText={mobileText}
      topPadding={topPadding}
      topPaddingMobile={topPaddingMobile}
      bottomPadding={bottomPadding}
      bottomPaddingMobile={bottomPaddingMobile}
    />
  )
}

export default DatoCmsComponentTextHero
