import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import colors from "@constants/colors"
import { columns } from "@constants/layout"
import { body, h6, bodyBold } from "@constants/typography"
import { Map } from "@assets/svg/mapSVG"
import BackgroundImage, {
  Container as BackgroundImageContainer,
} from "@components/BackgroundImage"
import { DatoResponsiveImage } from "@dato/types"
import measurements from "@constants/measurements"
import Link from "next/link"

const Container = styled.div<{
  map: boolean
  backgroundColor: string
  hasCTA?: boolean
}>`
  ${({ map, backgroundColor, hasCTA }) => css`
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow: hidden;
    width: 100%;
    padding: 64px 0 ${hasCTA ? "128px" : "64px"};
    background-color: ${map ? colors.snow : backgroundColor};

    ${up("laptop")} {
      padding: 108px 0 ${hasCTA ? "164px" : "108px"};
    }
  `}

  ${BackgroundImageContainer} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Content = styled.div<{ blockWidth: number }>`
  ${({ blockWidth }) => css`
    z-index: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;

    ${up("laptop")} {
      width: ${columns(blockWidth)};
    }
  `}
`

const Heading = styled.h6<{
  color?: string
  useTextBackgroundOverlay?: boolean
}>`
  ${({ color, useTextBackgroundOverlay }) => css`
    ${h6}
    margin: 0 0 20px 0;
    background-color: ${useTextBackgroundOverlay ? colors.divider30 : "none"};
    line-height: 42px;
    text-align: center;
    color: ${color ? `#${color}` : colors.black};

    ${up("laptop")} {
      margin-bottom: 42px;
      color: ${color ? `#${color}` : colors.obsidian};
    }
  `}
`

const Body = styled.p<{ color?: string; useTextBackgroundOverlay }>`
  ${({ color, useTextBackgroundOverlay }) => css`
    ${body}
    margin: 0;
    background-color: ${useTextBackgroundOverlay ? colors.divider30 : "none"};
    text-align: center;
    color: ${color ? `#${color}` : colors.slateDark};
  `}
`

const Cta = styled.div<{ ctaFont?: string; ctaBg?: string }>`
  ${({ ctaFont, ctaBg }) => css`
    ${bodyBold}
    align-items: center;
    align-self: center;
    justify-content: center;
    display: flex;
    width: auto;
    height: ${measurements.BUTTON_HEIGHT};
    outline: none;
    border: none;
    border-radius: 2px;
    background: ${ctaBg ? ctaBg : colors.snow};
    text-align: center;
    color: ${ctaFont ? ctaFont : colors.obsidian};
    padding: 0 24px;
    position: absolute;
    bottom: 40px;

    @media (min-width: 500px) {
      max-height: 60px;
      padding: auto;
    }

    ${up("laptop")} {
      &:hover {
        cursor: pointer;
      }
    }
    text-align: center;
  `}
`

const StyledMap = styled(Map)`
  position: absolute;
  top: -40px;
  left: 10px;

  ${up("laptop")} {
    top: -20px;
    left: 220px;
    transform: scale(1.5);
  }
`

export interface BaseInfoBlockProps {
  heading: string
  body: string
  headingColor?: string
  bodyColor?: string
  backgroundImage?: {
    responsiveImage: DatoResponsiveImage
  }
  useTextBackgroundOverlay?: boolean
  map?: boolean
  blockWidth: number
  cta?: string
  ctaFont?: string
  ctaBg?: string
  ctaLink?: string
}

interface ExtendedInfoBlockProps extends BaseInfoBlockProps {
  backgroundColor: string
}

const Block: React.FC<ExtendedInfoBlockProps> = ({
  heading,
  headingColor,
  body,
  bodyColor,
  backgroundImage,
  useTextBackgroundOverlay,
  map,
  backgroundColor,
  blockWidth,
  cta,
  ctaFont,
  ctaBg,
  ctaLink = "",
}) => {
  return (
    <Container
      map={!!map}
      backgroundColor={backgroundColor!}
      hasCTA={cta != ""}
    >
      {map && <StyledMap />}
      {backgroundImage && (
        <BackgroundImage image={backgroundImage.responsiveImage} />
      )}
      <Content blockWidth={blockWidth}>
        <Heading
          color={headingColor}
          useTextBackgroundOverlay={useTextBackgroundOverlay}
        >
          {heading}
        </Heading>
        <Body
          color={bodyColor}
          useTextBackgroundOverlay={useTextBackgroundOverlay}
        >
          {body}
        </Body>
      </Content>
      {cta ? (
        <Cta ctaFont={ctaFont} ctaBg={ctaBg}>
          <Link href={ctaLink} passHref>
            {cta}
          </Link>
        </Cta>
      ) : (
        ""
      )}
    </Container>
  )
}

export default Block
