import React from "react"
import { DatoContentBaseProps } from "../../types"
import Download from "@components/Download"

interface DatoCmsComponentDownloadProps extends DatoContentBaseProps {}

const DatoCmsComponentDownload: React.FC<DatoCmsComponentDownloadProps> = ({
  download,
  text,
}) => {
  return <Download download={download.url} text={text} />
}

export default DatoCmsComponentDownload
