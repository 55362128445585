import React from "react"
import styled, { css } from "styled-components"
import { up, down } from "styled-breakpoints"
import colors from "@constants/colors"
import { bodyBold } from "@constants/typography"
import _ from "lodash"
import { DatoResponsiveImage } from "@dato/types"
import MarkdownIt from "markdown-it"
const Markdown = new MarkdownIt()
import YmmSelector from "@components/YmmSelector"

const Container = styled.div`
  display: block;
  position: relative;

  ${up("laptop")} {
    width: 100%;
    background-color: ${colors.silver};
  }

  ${up("maxWidth")} {
  }

  img {
    height: calc(100vh - 116px);
    width: 100%;
    object-fit: cover;

    &.mobile {
      display: none;
    }

    ${down("tablet")} {
      height: calc(100vh - 109px);
      max-height: none;
      object-position: top;

      &.desktop {
        display: none;
      }

      &.mobile {
        display: block;
      }
    }
  }
`

const YMMcontainer = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: 162px;
  bottom: 0;
  left  0;

  ${down("tablet")} {
    height: auto;
  }
`

const HeroText = styled.div<{
  hasYmm: boolean
  textColor: string
  textBgColor: string
}>`
  ${({ hasYmm, textColor, textBgColor }) => css`
    font-family: "Jost", sans-serif;
    position: absolute;
    top: 0;
    padding: 80px;
    font-size: 80px;
    font-weight: 300;
    line-height: 84px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: right;
    color: ${textColor ? textColor : `#000000`};
    background-color: ${textBgColor ? textBgColor : `#ffffff`};

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end;

      ${down("tablet")} {
        background-color: ${textBgColor ? textBgColor : `#ffffff`};
        padding: 20px;
        text-align: center;
        justify-content: center;
      }
    }

    ${down("tablet")} {
      height: calc(100vh - 350px);
      width: 100%;
      font-size: 40px;
      line-height: 48px;
      padding: 0;
      background-color: transparent;
    }

    strong {
      font-weight: 600;
    }

    height: ${hasYmm ? "calc(100% - 162px)" : "100%"};

    &.align-left {
      left: 0;
      right: auto;
    }

    &.align-right {
      right: 0;
      left: auto;
    }

    &.align-center {
      left: 0;
      right: 0;
      margin: auto;
    }
  `}
`

const Content = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px;

  ${up("laptop")} {
    flex-direction: row;
    padding: 0;
  }
`

const Filters = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  ${up("laptop")} {
    flex-direction: row;
    padding: 0;
  }
`

const Filter = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) => css`
    flex-grow: 1;
    margin: 0 8px;
    pointer-events: ${disabled ? "none" : "all"};
  `}
`

const Label = styled.div`
  ${bodyBold}
  color: ${colors.obsidian};

  ${up("laptop")} {
    margin-right: 55px;
  }
`

interface HeaderProps {
  heroText: string
  backgroundImage: DatoResponsiveImage
  mobileImage: DatoResponsiveImage
  textAlignment: string
  textColor: string
  textBgColor: string

  ymmAttachment: {
    fontColor: string
    bgColor: string
  }
}

const PageHeader: React.FC<HeaderProps> = ({
  heroText,
  backgroundImage,
  mobileImage,
  textAlignment,
  textColor,
  textBgColor,
  ymmAttachment,
}) => {
  return (
    <Container>
      <img className="desktop" src={backgroundImage.src} />
      <img className="mobile" src={mobileImage.src} />
      {heroText.trim() != "" ? (
        <HeroText
          hasYmm={ymmAttachment ? true : false}
          textColor={textColor}
          textBgColor={textBgColor}
          className={
            textAlignment == "left"
              ? "align-left"
              : textAlignment == "right"
              ? "align-right"
              : "align-center"
          }
        >
          <div
            dangerouslySetInnerHTML={{
              __html: Markdown.render(heroText).replace(
                /(?:\r\n|\r|\n)/g,
                "<br>"
              ),
            }}
          />
        </HeroText>
      ) : (
        ""
      )}

      {ymmAttachment ? (
        <YMMcontainer>
          <YmmSelector
            fontColor={ymmAttachment[0]?.fontColor}
            bgColor={ymmAttachment[0]?.bgColor}
          />
        </YMMcontainer>
      ) : (
        <></>
      )}
    </Container>
  )
}

export default PageHeader
