import React from "react"
import { DatoContentBaseProps } from "../../types"
import TextBlock from "@components/TextBlock"

interface DatoCmsComponentTextBlockProps extends DatoContentBaseProps {}

const DatoCmsComponentTextBlock: React.FC<DatoCmsComponentTextBlockProps> = ({
  header,
  body,
  centerHeader,
  centerBody,
}) => {
  return (
    <TextBlock
      header={header}
      body={body}
      centerHeader={centerHeader}
      centerBody={centerBody}
    />
  )
}

export default DatoCmsComponentTextBlock
