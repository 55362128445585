import React from "react"
import { DatoContentBaseProps } from "../../types"
import CTABlock from "@components/CTABlock"

const formatBlock = ({ headline, url, buttonText, backgroundImage }) => ({
  headline,
  url,
  buttonText,
  backgroundImage: backgroundImage.responsiveImage,
})

interface DatoCmsComponentCtaBlockProps extends DatoContentBaseProps {}

const DatoCmsComponentCtaBlock: React.FC<DatoCmsComponentCtaBlockProps> = ({
  blocks,
}) => {
  return <CTABlock blocks={blocks.map(formatBlock)} />
}

export default DatoCmsComponentCtaBlock
