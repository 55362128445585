import React from "react"
import { DatoContentBaseProps } from "../../types"
import FeatureList from "@components/FeatureList"

interface DatoCmsComponentFeatureListProps extends DatoContentBaseProps {}

const DatoCmsComponentFeatureList: React.FC<DatoCmsComponentFeatureListProps> = ({
  header,
  features,
}) => {
  return <FeatureList header={header} features={features} />
}

export default DatoCmsComponentFeatureList
